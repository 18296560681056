window.$ = window.jQuery = require("jquery");
window.Popper = require("popper.js");
require("bootstrap");

$(document).ready(function(){
  $(".slick-slider").slick({
    // responsive: [
    //   {
    //     breakpoint: 9999,
    //     settings: "unslick"
    //   },
    //   {
    //     breakpoint: 767,
    //     settings: {
          autoplay: true,
          dots: true,
          dotsClass: 'slick-dots',
          infinite: true,
          speed: 300,
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          arrows: false,
          autoplaySpeed: 5000
    //     }
    //   }
    // ]
    // settings: "unslick",
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       // slidesToShow: 3,
    //       // slidesToScroll: 3,
    //       // infinite: true,
    //       // dots: true
    //       settings: "unslick"
    //     }
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1
    //     }
    //   }
    //   // You can unslick at a given breakpoint now by adding:
    //   // settings: "unslick"
    //   // instead of a settings object
    // ]
  });


          if ($(".grid").length > 0) {
            var $grid = $(".grid").imagesLoaded(function() {
              // init Isotope after all images have loaded
              $grid.isotope({
                layoutMode: "packery",
                packery: {
                  gutter: ".gutter-sizer",
                },
                // options
                itemSelector: ".grid-item",
                percentPosition: true,
                stagger: 30,
                //   layoutMode: 'fitRows'
              });
            });
          }
});

//jQuery to collapse the navbar on scroll

$(window).scroll(function() {
  if ($("#top").offset().top > 50){
    $(".fixed-top").addClass("top-nav-collapse");
  } else {
    $(".fixed-top").removeClass("top-nav-collapse");
  }
});

$('.page-scroll').click(function(){
  $('html,body').animate({ scrollTop: $('#pagecontent').offset().top - 130}, 1000);
  return false;
});

$(window).resize(function() {
  // console.log($(window).width());
  // var winWidth = $(window).width();
  // if(winWidth < 768) {
  //   $(".slick-slider").slick("slickFilter", "slide");
  // }
})

$(document).on("click", '[data-toggle="lightbox"]', function (event) {
  event.preventDefault();

  $(this).ekkoLightbox({
    // alwaysShowClose: true,
  });
});
